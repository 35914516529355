import React from 'react'
import { Link } from 'react-router-dom';
import {BiPhoneCall} from "react-icons/bi";
import {TfiEmail} from "react-icons/tfi";
import {FaWhatsapp} from "react-icons/fa";

const Contact = () => {

    return (
        <div className="container">
            <div className="row p-3 mb-2 text-center" data-aos="fade-up" style={{backgroundColor: "rgb(235 223 196)"}}>
                <div className="col-4">
                    <Link to="tel:13 54" style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <BiPhoneCall size={35} color="#caa33f"/>
                    </Link>
                    <span className="d-none d-md-block">
                        Pour les appels depuis la Côte d'Ivoire <br/>
                        <Link to="tel:13 54" style={{textDecoration: 'none'}}>1354</Link>
                    </span>
                </div>
                <div className="col-4">
                    <Link to="mailto:ecoute.ci@nsiaassurances.com" style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <TfiEmail size={35} color="#caa33f"/>
                    </Link>
                    <span className="d-none d-md-block">
                        Par Mail <br/>
                        <Link to="mailto:ecoute.ci@nsiaassurances.com" style={{textDecoration: 'none'}}>ecoute.ci@nsiaassurances.com</Link>
                    </span>
                </div>
                <div className="col-4">
                    <Link to="https://wa.me/+2250575102102" style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                        <FaWhatsapp size={35} color="#caa33f"/>
                    </Link>
                    <span className="d-none d-md-block">
                        Par WhatsApp <br/>
                        <Link to="https://wa.me/+2250575102102" style={{textDecoration: 'none'}}>+225 05 75 102 102</Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Contact