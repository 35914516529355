import axios from "axios";
import qs from "qs";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Allmail from "./Allmail.js";
import Chronogramme from "../home/Chronogramme";
import ChronogrammeMobile from "../home/chronogrammeMobile";

export default function AllmailContainer() {
  return (
    // <section style={{backgroundImage: `url(./img/backgrounds/home.jpg)`, top: "center",}} id="hero" className="in-containerlog">
    <section id="hero" className="in-containerlog d-flex align-items-center justify-content-center">
      <div className="container-fluid login-inner mt-4 mx-5">
        <div className="row">
          {/*<div className="fixed-element col-md-4 col-xl-3 d-none d-md-block">*/}
          {/*  <Chronogramme />*/}
          {/*</div>*/}
          {/*<div className="d-block d-md-none">*/}
          {/*  <ChronogrammeMobile />*/}
          {/*</div>*/}
          <div className="scrollable-element col-12 mt-8 px-md-5">
            <Allmail />
          </div>
        </div>
      </div>
    </section>
  );
}
