import React from 'react'
import './login.css'
import LoginForm from './LoginForm.js';
import './login.css'

const Login = () =>
{
    return (
        // <section id='hero' style={{ backgroundImage: `url(./img/backgrounds/suivi.jpg)`, top: 'center', }} className="d-flex align-items-center justify-content-center">
        <section id='hero' className="d-flex align-items-center justify-content-center">
            <div className="container-fluid custom-sm" data-aos="fade-up" >
                <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 login-container-mobile" data-aos="fade-up">
                        <LoginForm />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login