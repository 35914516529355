import React, { useState, useEffect, useContext } from "react";
import Type from './Type.js';
import './typehome.css';
import { AppContext } from "../../../../context/ContextApi.js";

export default function TypeHome()
{
    const { selectedOption, setSelectedOption, selectedItem, setSelectedItem } = useContext(AppContext)

    return (
        <section className="d-flex flex-col items-center mt-16">
            <h3 className="rounded m-8 p-4 font-medium" style={{backgroundColor: "rgb(74 75 110 / 30%"}}>Avant de commencer la déclaration, veuillez entrer svp les informations sur le client afin de rechercher son contrat.</h3>
            <div className="container-fluid custom-sm" data-aos="fade-up">
                <div className="row justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 login-container-mobile" data-aos="fade-up">
                        <Type />
                    </div>
                </div>
            </div>
        </section>
    );
}
