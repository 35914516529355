import React, { useEffect, useMemo, useState, useContext } from "react";
import "./tablegrid.css";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import {AiFillFileText, AiOutlineArrowLeft, AiOutlineLogin,} from "react-icons/ai";
import { GiMoneyStack, GiReceiveMoney } from "react-icons/gi";
import { FaHistory } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { AppContext } from "../../../context/ContextApi";
import global_variables from "../../controllers/globalVar";
import AlertContent from "../../alertContent/AlertContent.js";
import Chronogramme from "./Chronogramme.js";

const TableGrid = () => {
    const {individuel, individuelNextStep, individuelPreviousStep, fileName, fileName2, fileName3, isCheck, selectedOption, setSelectedOption, selectedItem,
        setSelectedItem, declareAcceuil, setDeclareAcceuil, successful, setSuccessful, message, setMessage, showAlert, alert, researchResult, setSearchResult,
    } = useContext(AppContext);

    let dataTest = {
        data: {
            date_declaration: "",
            numero_declaration: "",
            declarant: "",
            polices: [{
                numero_police: "",
                statut_sinistre: "",
                statut_police: "",
            },
            {
                numero_police: "",
                statut_sinistre: "",
                statut_police: "",
            },
            {
                numero_police: "",
                statut_sinistre: "",
                statut_police: "",
            }],
        },
    };
    const [courier, setCourier] = useState([]);
    const [loading, setLoading] = useState(false);
    //getting data from localstorage
    let data = JSON.parse(localStorage.getItem("user")) || dataTest;
    let numDeclaration = useMemo(() => [data.data.numero_declaration], [data.data.numero_declaration,]);
    const navigate = useNavigate();
    const date = new Date(data.data.date_declaration);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate = date.toLocaleDateString("fr-FR", options);

    useEffect(() => {
        var data = qs.stringify({numero_declaration: `${numDeclaration}`,});
        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-couriers-declaration`,
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
            data: data,
        };

        axios(config).then((response) => {
                setCourier(response.data.data.nouveaux_courriers);
            })
            .catch((error) => {
                console.log(error);
            });
        }, [numDeclaration]);

    const handleCourierSinistre = async (id) => {
        setLoading(true);
        if (!id)
          return null;
        let key = id || "c92728a5-d0d0-4712-8f53-87c6aa2d2c68";
        var data = qs.stringify({token: `${key}`,});
        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-couriers-sinistre`,
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
            data: data,
        };
        axios(config).then((response) => {
            if (response.data.status === false && response.data.data.length === 0) {
                setLoading(false);
                toast.error(`${response.data.error}`, {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                localStorage.setItem("courrierSini", JSON.stringify(response.data));
                navigate("/couriersini");
            }
        })
        .catch((error) => {
            setLoading(false);
            toast.error("Désolé! Le service que vous tentez de joindre ne répond pas veuillez réessayer plus tard..", {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            console.log(error);
        });
    };
    if (loading) return <AlertContent />;

    return (
        <div className="card rounded-lg mt-sm-0 mt-md-5 mt-lg-5">
            <h4 className="card-header flex justify-center items-center py-3 text-xl text-center">POLICES ASSOCIEES A LA DECLARATION</h4>
            <div className="container">
                <div className="row">
                    {(data.data.polices.length > 0)
                        ? data.data.polices.map((police, index) => {
                            return (
                                <div className="col-12 m-2">
                                    <div className="card">
                                        <div className="card-body text-light">
                                            <h4 className="card-title">Police N° {police.numero_police}</h4>
                                            <p className="card-text">{police.libelle_produit}</p>
                                            <label className={`${police.statut_sinistre_id === 5
                                                ? "bg-red-400 " : police.statut_sinistre_id === 7
                                                    ? "bg-red-400 " : police.statut_sinistre_id === 6
                                                        ? "bg-green-500" : police.statut_sinistre_id === 8
                                                            ? "bg-green-500" : "bg-yellow-500"} text-black px-3 py-1 rounded-full dark:bg-yellow-900 dark:text-yellow-300`}>
                                                {police.statut_sinistre ? police.statut_sinistre : "En cours d'analyse"}
                                            </label>
                                        </div>
                                    </div>
                                </div>)
                            })
                        : (<h5 className="text-center text-muted py-2">Aucune police n'est associée à cette déclaration.</h5>)
                    }
                </div>
            </div>
            <div className="card-footer d-flex justify-content-center align-items-center">
                <Link style={{textDecoration: "none"}} to="/piece">
                    <button className="btn btn-sm btn-primary text-light d-flex m-1">
                        <AiFillFileText className="m-1"/>
                        <div className="d-none d-md-block">Pièces justificatives</div>
                    </button>
                </Link>
                <Link style={{textDecoration: "none", cursor: "pointer"}} to="/allmailContainer">
                    <button className="btn btn-sm btn-info text-light d-flex m-1">
                        <BsFillEnvelopeFill className="m-1"/>
                        <div className="d-none d-md-block">Courriers reçus</div>
                    </button>
                </Link>
                {/*<Link style={{textDecoration: "none", cursor: "pointer"}} to="/history">*/}
                {/*    <button className="btn btn-sm btn-info text-light d-flex m-1">*/}
                {/*        <FaHistory className="m-1"/>*/}
                {/*        <div className="d-none d-md-block">Historique</div>*/}
                {/*    </button>*/}
                {/*</Link>*/}
            </div>
        </div>
    );
};

export default TableGrid;
