import React from 'react'
import '../globalStyle.css';
import {Link} from "react-router-dom";
import {AiOutlineArrowLeft, AiOutlineCheck} from "react-icons/ai";
import {BiArrowBack} from "react-icons/bi";

const ProductionPieceContainer = () =>
{
    return (
        // <section id='hero' style={{ backgroundImage: `url(./img/backgrounds/home.jpg)`, top: 'center', }} className="d-flex align-items-center justify-content-center">
        <section id='hero' className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">
                <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 assistanceContainer" data-aos="fade-up">
                        <div className="icon-box box-mobile">
                            <div className="col-xl-12 col-md-4 col-lg-8 welcome-container py-3">
                                <h1 className='bvn-phrase'>Guide de production de pièces</h1>
                                <h2 className="plate-form text-secondary">Disponible bientôt !</h2>
                                <div className="flex justify-center align-center info-sous-btn">
                                    <Link to="/assistance" type="button"
                                          className="w-auto info-sous-return-btn flex justify-center items-center return-btn flex recherche-btn mx-2 px-3 py-2 w-100 text-2xl font-medium rounded"
                                          style={{textDecoration: 'none'}}>
                                        <AiOutlineArrowLeft className="mr-2"/> Retour
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductionPieceContainer