import React from 'react'
import './signupP.css'
import SignupMulti from '../individuelles/SignupMulti.js';

const SignupP = () => {
    return (
        // <section style={{ backgroundImage: `url(./img/backgrounds/declaration.jpg)`, top: 'center', }} id="hero" className="d-flex align-items-center justify-content-center">
        <section id="hero" className="d-flex align-items-center justify-content-center">
            <div className="container-fluid lightest-inner" data-aos="fade-up" >
                <div className='signup-container'>
                    <div className="row signup-inner justify-center gutter-mobile" data-aos="zoom-in" data-aos-delay="250">
                        <div className="col-12 signup-multi-mobile" data-aos="fade-up">
                            <SignupMulti />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignupP