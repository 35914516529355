import React, { useContext } from 'react'
import { AppContext } from '../../../context/ContextApi.js';
import RdvForm from './RdvForm.js';
import RdvRecap from './RdvRecap.js';
import Breadcrumb from "../faq/ContextViewer/Breadcrumb";
import {Link} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";

const RdvMulti = () =>
{
    const { step, rdvSteps } = useContext(AppContext)
    const renderCurrentSelection = () =>
    {
        switch (step)
        {
            case 1:
                return <RdvForm />
            case 2:
                return <RdvRecap />
            default:
                return null;
        }
    }

    return (
        <div className="card w-full rounded shadow">
            <div className="card-header flex justify-between items-center py-3">
                <h3>DEMANDE DE RENDEZ-VOUS</h3>
                <Link to="/assistance" type="button" className="btn bts-sm btn-light" style={{boxShadow: "2px 2px 2px 2px #e3e6e7"}}>
                    <span className="flex justify-center items-center">
                      <BiArrowBack size={20}/> <label className="d-none d-md-inline m-1">Retour</label>
                    </span>
                </Link>
            </div>
            <div className="card-body lightest-main">
                <div className="login-container-mobile">
                    {renderCurrentSelection()}
                </div>
            </div>
        </div>

    )
}

export default RdvMulti