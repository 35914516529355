import React from 'react'
import './assistanceContainer.css'
import { Link } from "react-router-dom";
import { RiFileSearchFill } from "react-icons/ri";
import { BsFillCalendar2CheckFill } from "react-icons/bs";
import { SiFiles } from "react-icons/si";
import { FaFileAudio } from "react-icons/fa";

const AssistanceContainer = () =>
{
    return (
        // <section id='hero' style={{ backgroundImage: `url(./img/backgrounds/assistance.jpeg)`, top: 'center', }} className="d-flex align-items-center justify-content-center">
        <section id='hero' className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">
                <div className="row login-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 assistanceContainer" data-aos="fade-up">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="icon-box box-mobile">
                                <div className="welcome-container p-3">
                                    <h1 className='bvn-phrase'>Bienvenue dans l'assistance</h1>
                                    <h3 className="plate-form">Cliquez sur l'onglet de votre choix pour être accompagné(e) dans votre déclaration de sinistre et le suivi de votre dossier.</h3>
                                    <div className="row mt-5 flex justify-center" style={{ color: '#ffffff' }}>
                                        <div className="col-4 col-md-3">
                                            <Link to="/faq" type="button" className="btn bts-sm btn-outline-light m-1 w-100">
                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                    <RiFileSearchFill size={35} color="#caa33f" />
                                                </span>
                                                <span className="d-block d-md-none">FAQ</span>
                                                <span className="d-none d-md-block">Foire aux questions</span>
                                            </Link>
                                        </div>
                                        <div className="col-4 col-md-3">
                                            <Link to="/rdv" type="button" className="col-md-3 btn bts-sm btn-outline-light m-1 w-100">
                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                    <BsFillCalendar2CheckFill size={35} color="#caa33f" />
                                                </span>
                                                <span className="d-block d-md-none">RDV</span>
                                                <span className="d-none d-md-block">Prise de rendez-vous</span>
                                            </Link>
                                        </div>
                                        <div className="col-4 col-md-3">
                                            <Link to="/guide" type="button" className="col-md-3 btn bts-sm btn-outline-light m-1 w-100">
                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                    <SiFiles size={35} color="#caa33f" />
                                                </span>
                                                <span className="d-block d-md-none">Guide</span>
                                                <span className="d-none d-md-block">Guide des pièces</span>
                                            </Link>
                                        </div>
                                        {/* <div className="col-4 col-md-3">
                                            <Link to="/tutorial" type="button" className="col-md-3 btn bts-sm btn-outline-light m-1 w-100">
                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                                    <FaFileAudio size={35} color="#caa33f" />
                                                </span>
                                                <span className="d-block d-md-none">Tuto</span>
                                                <span className="d-none d-md-block">Tutoriel d'utilisation</span>
                                            </Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AssistanceContainer