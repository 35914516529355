import axios from 'axios'
import qs from 'qs';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {AiOutlineArrowLeft } from 'react-icons/ai';
import global_variables from '../../controllers/globalVar.js';
import AlertContent from '../../alertContent/AlertContent.js';
import {BsFillEnvelopeFill, BsFillEnvelopeOpenFill} from "react-icons/bs";

export default function DeclarerCourier() {
    const [value, setValue] = React.useState(0);
    const [courier, setCourier] = useState([]);
    const [loading, setLoading] = useState(false);
    let data2 = JSON.parse(localStorage.getItem('user'));
    let numDeclaration = useMemo(() => [data2.data.numero_declaration], [data2.data.numero_declaration])

    useEffect(() => {
        setLoading(true)
        var data = qs.stringify({
            'numero_declaration': `${numDeclaration}`
        });
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-couriers-declaration`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config).then((response) => {
            setLoading(false)
            if (response.data.status === true) {
                setCourier(response.data.data.courriers);
            }
        })
        .catch((error) => {
            setLoading(false)
            console.log(error);
        });
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //userInfo
    let data = JSON.parse(localStorage.getItem('user'));
    if (loading) return <AlertContent />

    return (
        <>
            {/*<div className='card-body py-3 rounded-lg'>*/}
            {/*    <h4 className="text-4xl p-2 text-white font-mono leading-tight">LISTES DES COURRIERS DE DECLARATION</h4>*/}
            {/*    <div className="flex justify-around">*/}
            {/*        <div className="flex cutom-box">*/}
            {/*            <h4 className="custom-with-label text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">Numero declaration :</h4>*/}
            {/*            <dd className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">{data.data.numero_declaration}</dd>*/}
            {/*        </div>*/}
            {/*        <div className="flex cutom-box">*/}
            {/*            <h4 className="custom-with-label text-xl font-serif font-medium text-gray-300 md:text-xl dark:text-gray-400">Declarant :</h4>*/}
            {/*            <dd className="text-xl text-white custom-with-label font-mono mx-4 font-semibold">{data.data.declarant}</dd>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="card rounded-lg mt-sm-0 mt-md-5 mt-lg-5">
                <h4 className="card-header flex justify-center items-center py-3 text-3xl text-center">
                    LISTES DES COURRIERS DE DECLARATION {data.data.numero_declaration}
                </h4>
                <div className="card-body">
                    <div className="row relative">
                        <div className="col-12 table-responsive">
                            {(courier.length > 0)
                                ? (<table className="table table-sm table-nowrap min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            <th className="p-3 text-left text-gray-600 uppercase">Reférence</th>
                                            <th className="p-3 text-left text-gray-600 uppercase">Libellé</th>
                                            <th className="p-3 text-left text-gray-600 uppercase">Destinataire</th>
                                            <th className="p-3 text-left text-gray-600 uppercase">Date d'émission</th>
                                            <th className="p-3 text-left text-gray-600 uppercase">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='card-body tbody-mobile text-left'>
                                        {courier.map((courie, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.reference}</td>
                                                    <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.libelle}</td>
                                                    <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.destinataire}</td>
                                                    <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>{courie.date_creation}</td>
                                                    <td className={`${courie.date_ouverture.length === 0 ? 'courrier-pas-ouvert' : ''}`}>
                                                        <Link to={courie.url} target="_blank" type="button" title='courriers' className="no-underline relative text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 rounded-lg px-3 py-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                                                    <span className="flex justify-center items-center">
                                                        {(courie.date_ouverture.length === 0) ? <BsFillEnvelopeFill className='m-1' color='yellow' /> : <BsFillEnvelopeOpenFill className='m-1' color='green' />}
                                                        {(courie.date_ouverture.length === 0) ? "Ouvrir" : "Afficher"}
                                                    </span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )})
                                        }
                                    </tbody>
                                </table>)
                                : (<h4 className="text-center text-light">Vous n’avez pas encore de courrier disponible.</h4>)
                            }
                        </div>
                    </div>

                    <div className='declare-btn flex justify-center items-center'>
                        <Link to='/suivrehome' type="button" className="return-btn flex recherche-btn justify-center items-center py-2 px-5 text-2xl font-medium rounded" style={{ textDecoration: 'none' }}>
                            <AiOutlineArrowLeft className="mr-2" /> Retour
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}