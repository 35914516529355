import React from "react";
import { Link } from "react-router-dom";
import { FcAssistant } from "react-icons/fc";
import { TfiWrite } from "react-icons/tfi";
import { MdFollowTheSigns } from "react-icons/md";
import "./landing.css";

const LandingPage = () => {
    // if (localStorage.length > 0) {
    //     localStorage.clear();
    // } else {
    //     console.log("localStorage is empty.");
    // }
    let data = JSON.parse(localStorage.getItem("user"));

    return (
        <section style={{backgroundImage: `url(./img/backgrounds/home.jpg)`, top: "center"}} id="hero" className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">
                <div className="row justify-content-center align-items-center bvn" data-aos="fade-up"
                     data-aos-delay="150">
                    <div className="col-12 welcome-container p-3">
                        <h1 className="bvn-phrase">Bienvenue à <span style={{color: '#ffc10794'}}>NSIA Vie Assurances</span> !</h1>
                        <h3 className="plate-form">Nous sommes de tout cœur avec vous, prêts à vous accompagner à chaque étape de votre déclaration de sinistre en ligne.</h3>
                    </div>
                </div>
                <div className="row mx-5 mt-3" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 col-lg-4 mb-2">
                        {/*<Link className="box-link" data-tour="step-1" to="search">*/}
                        <Link className="box-link" data-tour="step-1" to="info">
                            <div className="icon-box d-flex align-items-center justify-content-between">
                                <h3 className="w-75">Je déclare un sinistre</h3>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                                    <TfiWrite size={60} color="#caa33f"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-4 mb-2">
                        <Link className="box-link" data-tour="step-2" to={(data !== null) ? 'suivrehome' : 'login'}>
                            <div className="icon-box d-flex align-items-center justify-content-between">
                                <h3 className="w-75"> Je suis ma déclaration</h3>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                                    <MdFollowTheSigns size={60} color="#caa33f"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-6 col-lg-4 mb-2">
                        <Link className="box-link" data-tour="step-3" to="assistance">
                            <div className="icon-box d-flex align-items-center justify-content-between">
                                <h3 className="w-75">Je me fais assister</h3>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                                    <FcAssistant size={60} color="#caa33f"/>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingPage;
