import React from "react";
import "./info.css";
import {AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineLogin} from "react-icons/ai";
import {Link} from "react-router-dom";

const Info = () => {

    return (
        <section id="hero" className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">
                <div className="row justify-content-center align-items-center bvn welcome-container p-3" data-aos="fade-up" data-aos-delay="150">
                    <div className="col-12">
                        <h2>Assurément à vos côtés.</h2>
                        <div className="content">
                            <p>
                                Parce que vous comptez pour nous, nous tenons à vous exprimer nos sincères condoléances
                                en ces moments si difficiles.
                                Afin de faciliter votre déclaration en ligne, nous vous invitons svp à vous munir de la
                                photo ou du scan de l’acte de décès du défunt / de la défunte.
                            </p>
                            <p>Vous avez aussi la possibilité de joindre d’autres pièces justificatives dont la liste
                                est à retrouver <Link to="">ici</Link>.</p>
                            <p>
                                La déclaration ne prendra que 10 minutes de votre précieux temps. <br/>
                                N’hésitez surtout pas à cliquez <Link to="/assistance">ici</Link> en cas de besoin d’assistance.
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center align-center text-center police-btn-container">
                        <Link to="/" type="button" className="w-auto flex justify-center items-center login-btn-mobile return-btn recherche-btn mx-2 py-2 px-3 w-100 text-2xl font-medium rounded"
                              style={{textDecoration: "none"}}>
                            <AiOutlineArrowLeft className="mr-2"/> Retour
                        </Link>
                        <Link to="/search" type="button" className="w-auto flex justify-center login-btn flex recherche-btn justify-center items-center mx-2 py-2 px-3 w-100 text-2xl font-medium text-black bg-amber-500  rounded border border-blue-700 focus:ring-4 focus:outline-none focus:bg-amber-400 hover:bg-amber-400 focus:bg-amber-400"
                              style={{textDecoration: "none"}}>
                            Continuer <AiOutlineArrowRight className="ml-2"/>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Info;
