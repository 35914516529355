import React from 'react';
import './piecehome.css';
import TableGrid from './TableGrid.js';
import Chronogramme from '../home/Chronogramme.js';
import ChronogrammeMobile from "../home/chronogrammeMobile";

const PieceHome = () => {
    return (
        // <section style={{ backgroundImage: `url(./img/backgrounds/home.jpg)`, top: 'center', }} id="hero" className="in-containerlog">
        <section id="hero" className="in-containerlog d-flex align-items-center justify-content-center">
            <div className='container-fluid login-inner mx-5'>
                <div className='row'>
                    {/*<div className="fixed-element col-sm-6 col-md-4 col-xl-3 d-none d-md-block">*/}
                    {/*    <Chronogramme />*/}
                    {/*</div>*/}
                    {/*<div className="d-block d-md-none">*/}
                    {/*    <ChronogrammeMobile />*/}
                    {/*</div>*/}
                    <div className="scrollable-element col-12 mt-8 px-md-5">
                        <TableGrid />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PieceHome