import React from 'react'
import './suivrehome.css'
import TableGrid from './TableGrid.js';
import Chronogramme from './Chronogramme.js';
import ChronogrammeMobile from "./chronogrammeMobile";

const SuivreHome = () => {
    return (
        // <section style={{ backgroundImage: `url(./img/backgrounds/home.jpg)`, top: 'center', }} id="hero" className="in-containerlog">
        <section id="hero" className="in-containerlog d-flex align-items-center justify-content-center">
            <div className='container-fluid mt-4'>
                <div className='row'>
                    {/*<div className="fixed-element col-md-9 d-none d-md-block">*/}
                    <div className="scrollable-element col-md-8 px-md-3">
                        <Chronogramme/>
                    </div>
                    <div className="scrollable-element col-md-4 px-md-3">
                        <TableGrid/>
                    </div>
                    {/*<div className="d-block d-md-none">*/}
                    {/*    <ChronogrammeMobile/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    )
}

export default SuivreHome