import React, { useState, useEffect } from 'react';
import { BsFileEarmarkLock2 } from 'react-icons/bs';
import { GiCheckMark } from 'react-icons/gi';
import { RxCross2 } from 'react-icons/rx';
import { FaRegClock } from "react-icons/fa";
import './chronogramme.css';
import {Link} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";

const Chronogramme = () =>
{
    const [myData, setMyData] = useState({});
    const [updateContextStoreData, setUpdateContextStoreData] = useState(false);
    let data = JSON.parse(localStorage.getItem("user"));

    useEffect(() =>
    {
        let storeData = localStorage.getItem('user');
        if (storeData !== null)
        {
            storeData = JSON.parse(storeData);
            setMyData(storeData);
        }
    }, [updateContextStoreData])
    return (
        <div className="card mt-sm-0 mt-md-5 mt-lg-5">
            <h4 className="card-header flex justify-center items-center py-3 text-3xl text-center">
                ETAT DE PROGRESSION DE LA DECLARATION {data.data.numero_declaration}
            </h4>
            {/*<div className="card-body">*/}
            {/*<div className="flex flex-col md:grid grid-cols-12 text-gray-50 mt-4">*/}
            {/*    <div className="flex md:contents">*/}
            {/*        <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">*/}
            {/*            <div className="h-full w-6 flex items-center justify-center">*/}
            {/*                <div className={`h-full w-1 ${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none`} />*/}
            {/*            </div>*/}
            {/*            <div className={`w-12 h-12 absolute top-10 -ml-3 p-2 rounded-full ${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} shadow text-center`}>*/}
            {/*                {myData.data?.chronogramme.declaration.status == 'validé' ? <GiCheckMark size={30} color='#fff' /> : <BsFileEarmarkLock2 size={30} color='#fff' />}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={`${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} col-start-4 col-end-12 p-3 my-3 -ml-3 rounded-xl shadow-md w-full`}>*/}
            {/*            <h3 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.declaration.title}</h3>*/}
            {/*            <p className="flex leading-tight text-start">{myData.data?.chronogramme.declaration.details.status == true ? <GiCheckMark size={20} color='#ffffff' /> : <RxCross2 size={20} color='#ef4444d9' /> } {myData.data?.chronogramme.declaration.details.libelle}</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className="flex md:contents">*/}
            {/*        <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">*/}
            {/*            <div className="h-full w-6 flex items-center justify-center">*/}
            {/*                <div className={`h-full w-1 ${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none`} />*/}
            {/*            </div>*/}
            {/*            <div className={`w-12 h-12 absolute top-10 -ml-3 p-2 rounded-full ${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} shadow text-center`}>*/}
            {/*                {myData.data?.chronogramme.analyse.status == 'validé' ? <GiCheckMark size={30} color='#fff' /> : <BsFileEarmarkLock2 size={30} color='#fff' />}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={`${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} col-start-4 col-end-12 p-3 my-3 -ml-3 rounded-xl shadow-md w-full`}>*/}
            {/*            <h3 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.analyse.title}</h3>*/}
            {/*            {myData.data?.chronogramme.declaration.status == 'validé' ?*/}
            {/*                <p className="flex leading-tight text-start">{myData.data?.chronogramme.analyse.status == 'validé' ? <GiCheckMark size={20} color='#ffffff' /> : (myData.data?.chronogramme.analyse.details.pieces.status === true ? <GiCheckMark size={20} color='#22c55ed9' /> : <RxCross2 size={20} color='#ef4444d9' />)} {myData.data?.chronogramme.analyse.details.pieces.libelle}</p>*/}
            {/*                : <p className="flex leading-tight text-start">En attente de la validation de la déclaration de sinistre</p>*/}
            {/*            }*/}
            {/*            {myData.data?.chronogramme.analyse.details.polices.map((police, index) => {*/}
            {/*                return (*/}
            {/*                    <p key={index} className="flex leading-tight text-start">{myData.data?.chronogramme.analyse.status == 'validé' ? <GiCheckMark size={20} color='#ffffff' /> : (police.status === true ? <GiCheckMark size={20} color='#22c55ed9' /> : <RxCross2 size={20} color='#ef4444d9' />)} {police.libelle}</p>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className="flex md:contents">*/}
            {/*        <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">*/}
            {/*            <div className="h-full w-6 flex items-center justify-center">*/}
            {/*                <div className={`h-full w-1 ${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none`} />*/}
            {/*            </div>*/}
            {/*            <div className={`w-12 h-12 absolute top-10 -ml-3 p-2 rounded-full ${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} shadow text-center`}>*/}
            {/*                {myData.data?.chronogramme.reglement.status == 'validé' ? <GiCheckMark size={30} color='#fff' /> : <BsFileEarmarkLock2 size={30} color='#fff' />}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={`${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} col-start-4 col-end-12 p-3 my-3 -ml-3 rounded-xl shadow-md w-full`}>*/}
            {/*            <h3 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.reglement.title}</h3>*/}
            {/*            {myData.data?.chronogramme.reglement.details.polices.map((police,index) =>*/}
            {/*            {*/}
            {/*                return (*/}
            {/*                    <p key={index} className="flex leading-tight text-start">{myData.data?.chronogramme.reglement.status == 'validé' ? <GiCheckMark size={20} color='#ffffff' /> : (police.status === true ? <GiCheckMark size={20} color='#22c55ed9' /> : (police.status === false ? <RxCross2 size={20} color='#ef4444d9' /> : ''))} {police.libelle}</p>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="card-body">
                <div className="container">
                    <ul className="timeline timeline-horizontal">
                        <div className="row">
                            <div className="col-md-4">
                                <li className="timeline-item">
                                    <div
                                        className={`${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} timeline-badge`}>
                                        {myData.data?.chronogramme.declaration.status == 'validé' ?
                                            <GiCheckMark size={45} color='#fff'/> :
                                            <BsFileEarmarkLock2 size={45} color='#fff'/>}
                                    </div>
                                    <div
                                        className={`${myData.data?.chronogramme.declaration.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.declaration.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} rounded-xl shadow-md timeline-panel`}>
                                        <div className="timeline-heading text-start">
                                            <h4 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.declaration.title}</h4>
                                            <p><small className="flex align-items-center text-light"><FaRegClock
                                                className="mr-1"/> {myData.data?.chronogramme.declaration.details.date_creation}
                                            </small></p>
                                        </div>
                                        <div className="timeline-body">
                                            <p className="flex leading-tight text-start">
                                                {myData.data?.chronogramme.declaration.details.status == true ?
                                                    <GiCheckMark size={20} color='#ffffff'/> :
                                                    <RxCross2 size={20} color='#ef4444d9'/>}
                                                {myData.data?.chronogramme.declaration.details.libelle}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </div>

                            <div className="col-md-4">
                                <li className="timeline-item">
                                    <div className={`h-full w-1 ${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none timeline-badge`}>
                                        {myData.data?.chronogramme.analyse.status == 'validé' ?
                                            <GiCheckMark size={45} color='#fff'/> :
                                            <BsFileEarmarkLock2 size={45} color={myData.data?.chronogramme.reglement.status == 'en attente' ? '#0b1e5e' : '#fff'} />}
                                    </div>
                                    <div className={`${myData.data?.chronogramme.analyse.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.analyse.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} rounded-xl shadow-md timeline-panel`}>
                                        <div className="timeline-heading text-start" style={{color: myData.data?.chronogramme.reglement.status == 'en attente' ? '#0b1e5e' : '#fff'}}>
                                            <h4 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.analyse.title}</h4>
                                        </div>
                                        <div className="timeline-body" style={{color: myData.data?.chronogramme.reglement.status == 'en attente' ? '#0b1e5e' : '#fff'}}>
                                            {myData.data?.chronogramme.declaration.status == 'validé'
                                                ? <p className="flex leading-tight text-start">
                                                    {myData.data?.chronogramme.analyse.status == 'validé'
                                                        ? <GiCheckMark size={20} color='#ffffff'/>
                                                        : (myData.data?.chronogramme.analyse.details.pieces.status === true ?
                                                            <GiCheckMark size={20} color='#22c55ed9'/> :
                                                            <RxCross2 size={20} color='#ef4444d9'/>)
                                                    }
                                                    {myData.data?.chronogramme.analyse.details.pieces.libelle}</p>
                                                :
                                                <p className="flex leading-tight text-start">En attente de la validation de la déclaration de sinistre</p>
                                            }
                                            {myData.data?.chronogramme.analyse.details.polices.map((police, index) => {
                                                return (
                                                    <p key={index} className="flex leading-tight text-start">
                                                        {myData.data?.chronogramme.analyse.status == 'validé'
                                                            ? <GiCheckMark size={20} color='#ffffff'/>
                                                            : (police.status === true ?
                                                                <GiCheckMark size={20} color='#22c55ed9'/> :
                                                                <RxCross2 size={20} color='#ef4444d9'/>)
                                                        }
                                                        {police.libelle}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </li>
                            </div>

                            <div className="col-md-4">
                                <li className="timeline-item">
                                    <div className={`h-full w-1 ${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300 '} pointer-events-none timeline-badge`}>
                                        {myData.data?.chronogramme.reglement.status == 'validé' ?
                                            <GiCheckMark size={45} color='#fff'/> :
                                            <BsFileEarmarkLock2 size={45} color={myData.data?.chronogramme.reglement.status == 'en attente' ? '#0b1e5e' : '#fff'} />}
                                    </div>
                                    <div
                                        className={`${myData.data?.chronogramme.reglement.status == 'en cours' ? 'bg-yellow-900' : myData.data?.chronogramme.reglement.status == 'validé' ? 'bg-green-500' : 'bg-gray-300'} rounded-xl shadow-md timeline-panel`}>
                                        <div className="timeline-heading text-start" style={{color: myData.data?.chronogramme.reglement.status == 'en attente' ? '#0b1e5e' : '#fff'}}>
                                            <h4 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.reglement.title}</h4>
                                        </div>
                                        <div className="timeline-body" style={{color: myData.data?.chronogramme.reglement.status == 'en attente' ? '#0b1e5e' : '#fff'}}>
                                            {myData.data?.chronogramme.reglement.details.polices.map((police, index) => {
                                                return (
                                                    <p key={index} className="flex leading-tight text-start">
                                                        {myData.data?.chronogramme.reglement.status == 'validé'
                                                            ? <GiCheckMark size={20} color='#ffffff'/>
                                                            : (police.status === true ? <GiCheckMark size={20}
                                                                                                     color='#22c55ed9'/> : (police.status === false ?
                                                                <RxCross2 size={20} color='#ef4444d9'/> : ''))
                                                        }
                                                        {police.libelle}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            {/*<div className="declare-btn flex justify-center items-center my-3">*/}
            {/*    <Link to="/login" type="button"*/}
            {/*          className="return-btn relative flex recherche-btn justify-center items-center py-2 px-5 text-2xl font-medium  rounded"*/}
            {/*          style={{textDecoration: "none"}}>*/}
            {/*        <AiOutlineArrowLeft className="mr-2"/> Retour*/}
            {/*    </Link>*/}
            {/*</div>*/}
        </div>
    );
};

export default Chronogramme;

