import React from 'react'
import './successContainer.css'
import Success from '../individuelles/Success.js';

const SuccessContainer = () =>
{
    return (
        // <section style={{ backgroundImage: `url(./img/backgrounds/declaration.jpg)`, top: 'center' }} id="hero" className="d-flex align-items-center justify-content-center">
        <section style={{ backgroundImage: `url(./img/backgrounds/home.jpg)`, top: 'center' }} id="hero" className="d-flex align-items-center justify-content-center">
            <div className="container-fluid custom-sm" data-aos="fade-up" >
                <div className="row signup-inner justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 login-container-mobile" data-aos="fade-up">
                        <Success />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SuccessContainer